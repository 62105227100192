
	@import '../assets/css/scss-variables.scss';

    .comp-call-to-action {
        display: grid;
        gap: var(--spacer-block-with-fallback);
        align-items: stretch;

        & > * {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            text-align: center;
        }

        .link-button {
            margin-top: calc(var(--spacer-em-half) * 1.5);
        }

        p {
            hyphens: none;
        }

        @media (min-width: $mediaquery-tablet-landscape) {
            grid-template-columns: 1fr 1fr;
            gap: var(--column-gap);
            justify-content: center;
            
            & > * {
                padding: var(--padding-outside);
            }

            // & > div:nth-child(1) {
            //     grid-column: span 3;
            // }

            // & > div:nth-child(2) {
            //     grid-column: span 4;
            // }
        }
    }
